$quickSand: Quicksand, sans-serif;

// ----------------------
// COLORS
// ----------------------

$relayfy-lightgrey: #ebebeb;
$relayfy-grey: #b7b7b7;
$relayfy-darkgrey: #636363;
$relayfy-mercury: #e1e1e1;
$relayfy-iron: #e9e9ea;
$relayfy-white: #fff;
$relayfy-black: #000;
$relayfy-gold: #ffcc06;
$relayfy-blue: #28c1db;
$relayfy-darkblue: #00a0c2;
$relayfy-darkred: #ef4023;
$relayfy-red: red;

$colors: (
  lightgrey: #ebebeb,
  grey: #b7b7b7,
  darkgrey: #636363,
  mercury: #e1e1e1,
  iron: #e9e9ea,
  white: #fff,
  black: #000,
  darkred: #94161a
);

// ----------------------
// BREAKPOINTS
// ----------------------
$breakpoints: (
  small: 640px,
  medium: 1024px,
  large: 1200px
);
$breakpoint-classes: (small medium large);

@mixin breakpoint($size) {
  @if map-has-key($breakpoints, $size) {
    @media all and (max-width: map-get($breakpoints, $size)) {
      @content;
    }
  } @else {
    @warn "Unknown `#{$size}` in $breakpoints.";
  }
}

@mixin custom_breakpoint($screen) {
  @media (max-width: ($screen + "px")) {
    @content;
  }
}

.errorBase {
  color: #dd3333;
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
}

.warningBase {
  color: #ffcc00;
  @extend .errorBase;
}
