@import "../../UI/mixin.scss";

.customize {
  max-width: 450px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow-x: hidden;

  .modalInner {
    .scrollable {
      position: absolute;
      overflow-y: auto;
      height: 80%;
      padding-bottom: 64px;
    }

    .header {
      min-height: 54px;
      display: flex;
      justify-content: start;
      align-items: center;
      color: white;
      width: 100%;
      padding: 0 10px;
      top: 0;
      z-index: 3;
      font-size: 18px;

      > h5 {
        margin: 5% 0 !important;
      }

      .backIcon {
        margin: 0 12px;
      }

      .optionName {
        width: 100%;
      }
    }

    .description {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
    }

    .details {
      .typography {
        font-size: 14px;
        font-weight: 400;
        color: #49454f;
      }

      .selectRadio {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px !important;
        max-height: 40px;

        @include breakpoint(small) {
          font-size: 14px;
        }
      }

      .request {
        width: 100%;
        text-align: center;
        margin: 10px;
      }

      .sectionWrap {
        display: flex;
        flex-direction: column;
        margin: 12px 0;
        gap: 12px;
      }
    }

    .actionButtons {
      z-index: 1;
      width: 100%;
      position: fixed;
      bottom: 0;
      background-color: #fff;
      .scrollButton {
        position: absolute;
        right: 4px;
        top: -50px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 50%;
        font-size: 1.3em;
      }
    }
  }
}

.disabledReason {
  margin: 20px;
  color: blue;
  font-weight: 900;
  font-size: 20px;
  text-align: center;
}

.actionButton {
  &:disabled {
    pointer-events: unset !important;
    cursor: not-allowed !important;
  }
}
