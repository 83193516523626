@import '../UI/mixin.scss';
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

$inputHeight: 40px;
$iconSize: 40px;
$labelColor: $relayfy-black;
$gapSize: 22px;
// $linkColor: white;
$linkColor: black;
// $linkColor: #fafafa;

.accountHeader {
  display: flex;
  // padding-top: 6em;
  padding: 1em 1em;
  gap:1rem;
  width: 100%;
  line-height: 2.4em !important;
  // margin-bottom: 1.5em;
}

.backBtn {
  &:hover {
    cursor: pointer;
  }
display: flex;
  justify-content: center;
  align-items: center;
  // margin: 1.4em;
  // padding: 0.6em;
  // position: fixed;
  // top: 0;
  // left: 0;
}

.appTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  // margin-bottom: 10px;
  height: 1.5em;
  text-align: center;
}

.pageTitle {
  line-height: 30px;
  // margin-bottom: 40px;
  margin: 1.5rem 0 2rem 0;
  font-size: 24px;
  text-align: left;
  color: #49454F;
}

.form {
  text-align: center;
}

.inputField {
  margin-bottom: $gapSize;
}

.signInBtn {
  display: flex;
  width: 18%;
  height: $inputHeight - 2px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  margin-right: 2px;
  background-color: $relayfy-gold;
}

.elemWithIcon {
  &_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: $relayfy-white;
    border-radius: 25px;
    height: $inputHeight;
  }

  &_bottomGap {
    margin-bottom: $gapSize;
  }

  &_inputWrap {
    @extend .elemWithIcon_wrapper;
  }

  &_buttonWrap {
    @extend .elemWithIcon_wrapper;
    margin-bottom: $gapSize;
  }

  &_icon {
    max-height: 30px;
    max-width: 30px;
  }

  &_iconWrap {
    display: inline-block;
    width: 10%;
    vertical-align: middle;
    color: $relayfy-gold;
  }

  &_element {
    height: 100%;
    width: 80%;
    color: $labelColor;
    text-align: center;
    border: none;
    padding: 0px;
    font-size: 1em;
    font-weight: 900;
  }

  &_button {
    &:hover {
      color: $relayfy-gold;
      cursor: pointer;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_input:focus {
    outline-width: 0;
  }
}

.link {
  font-weight: 800;
  opacity: 0.8;
  color: $linkColor;
  text-decoration: underline;
  font-size: 18px;

  &:hover {
    color: $linkColor;
    text-decoration: underline;
    opacity: 0.8;
  }

  &_bottom {
    font-size: 20px;
  }
}

.backLink {
  @extend .link;
  font-size: 20px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.alternatives {
  display: flex;
  justify-content: center;
  gap:1rem;
}

.signIn {
  &_alternatives {
    @extend .alternatives;
    justify-content: space-between;
  }
}

.signUp {
  &_alternatives {
    @extend .alternatives;
    margin-top: $gapSize;
  }

  &_button {
    margin-bottom: 0;
  }
}

.forgot {
  &_info {
    margin-bottom: $gapSize;
  }

  &_button {
    margin-bottom: 0;
  }

  &_links {
    margin-top: $gapSize;
  }

  &_backBtn {
    font-size: 20px;
  }

}

.ext {

  &_errPopup {
    &_content {
      color: $relayfy-black;
    }

    &_footer {
      color: "#FEA833";
      min-height: 40px;

      &_separator {
        opacity: 0.5;
      }
    }

  }
}

.separator {
  display: flex;
  align-items: center;
  margin: $gapSize 0px;

  &_label {
    padding: 0 2rem;
  }

  &_line {
    flex: 1;
    height: 1px;
    // background-color: $relayfy-white;
    background-color: $relayfy-black;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.messages {
  text-align: left;
  font-style: italic;
  color: $relayfy-mercury;
  font-size: 18px;
  margin: $gapSize 0;

  .error {
    font-weight: 900;
    color: $relayfy-red;
  }

  .info {
    font-weight: 700;
    color: #0066ff;
  }

  .success {
    font-weight: 700;
    color: rgb(140, 255, 140);
  }
}
