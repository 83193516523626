@import "../UI/mixin.scss";
// .tabWrap {
//   text-align: center;
//   overflow-x: auto;
//   width: 100%;
//   // position: fixed;
//   z-index: 1;
//   left: 0;
//   // top: 50px;

//   &::-webkit-scrollbar {
//     display: none;
//   }
// }
.sliderWrap {
  padding: 0px 0px;
}
// .parentTab{
//   gap : "32px";
//   background-color:$relayfy-lightgrey;
//   width: auto;
//   // padding: 13px 17px;
//   position: relative;
//   height: 72px;
// }
// // .parentTab .active {
// //   border-bottom: 3px solid #292929;
// // }
// .parentTab div {
//   // margin-top: 4px;
//   width: 100% !important;
//   color: rgba(0,0,0,.87);
//   font-family: 'Roboto', sans-serif;
//   font-size: 21px;
//   font-weight: 500;
//   line-height: 34px;
//   letter-spacing: 0.1px;
//   text-align: center;
// }
// .tabs {
//   display: inline-flex;
//   justify-content: left;
//   width: 100%;
// }
// @media (max-width:991px)
// {
//   .parentTab {
//     // width: 13%;
// }

// }
@media (max-width:767px)
{
.category-slider.slick-slider {
  position: static;
}
.category-slider .slick-prev {
  top: 30px !important;
  left: 11px !important;
  transform: translate(0) !IMPORTANT;
}
.category-slider .slick-next {
  left: initial;
  right: 11px !important;
  top: 17px !important  ;
  transform: translate(0) !IMPORTANT;
}
}
// .tab {
//   justify-content: center;
//   border-top: none;
//   font-weight: bold;
//   color: rgb(255, 255, 255);
//   // padding: 10px 13px;
//   // font-size: 16px;
//   display: flex;
//   align-items: center;
//   border-bottom-left-radius: 20px;
//   border-bottom-right-radius: 20px;
//   white-space: nowrap;
// }
