.wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left:0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);

  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;

  -webkit-justify-content: center;
  justify-content: center;

  align-items: center;
}
