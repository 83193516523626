@import "../UI/mixin.scss";

.rewards {
  min-width: 320px;

  @include breakpoint(small) {
    // width: 100vw;
  }

  &_open {
    margin-left: 14px;
    animation-name: bounce-4;
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-iteration-count: 13;

    &_icon {
      color: $relayfy-gold;
    }
  }

}

.rewardsContainer {
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes bounce-4 {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.9, 1.1) translateY(-10px);
  }

  50% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}
