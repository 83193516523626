@import "../UI/mixin.scss";
.tips {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 20px;
  width: 100%;
  
  @include breakpoint(small) {
    justify-content: space-between;
  }
  
  .label {
    margin-right: 4%;
    display: inline-block;
    font-weight: bold;
  }
  
  .list {
    width: 100%;
    // height: 45px;
    // font-size: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
    margin: 0 2%;
    @include breakpoint(small) {
      margin: 0 0;
    }
  }

  .tipBtn {
    
  }

  .customTip {
    width: 30%;
  }
}
