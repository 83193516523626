@import '../../components/UI/mixin.scss';

.foodCourt {

  @include breakpoint(small) {
    display: flex;
    flex-direction: column;
  }

  &_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 10px;
    column-gap: 10px;

    padding: 2%;

    @include breakpoint(medium) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include breakpoint(small) {
      display: block;
      flex-grow: 1;
    }
  }

  .footer {

    background-color: $relayfy-black;
    color: $relayfy-white;
    height: 100px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    >* {
      margin-bottom: 10px;
    }

    a {
      color: $relayfy-white;
    }

    &_copyright {
      color: #9c9c9c;
    }
  }
}