.MuiButtonBase-root{
	padding:5px;
}

.MuiSvgIcon-fontSizeSmall{
	max-width:18px;
}

.MuiFormGroup-row{
	justify-content: space-between;
	>div{
		max-height:50px;
	}
}



