@import "../UI/mixin.scss";
.errorMsg {
  @extend .errorBase;
  text-align: center;
}
.cartcontainer {
  margin-bottom: 20px;
  width: 100%;
}

.revieworderbtn {
  width: 394px;
  height: 52px;
  left: 11px;
  border-radius: 100px;
  gap: 8px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  color: white;
  bottom: 10px;
}
.orderList {
  text-align: center;
}
.cartcontainer {
  text-align: center;
}
@media (max-width: 767px) {
  .revieworderbtn {
    width: 96%;
    font-size: 19px;
  }
}
