@import "../UI/mixin.scss";

.errorMsg {
  @extend .errorBase;
  text-align: center;
}

.MuiListItem-container {
  left: 4px;
}

.dialogTitle {
  width: 412px;
  height: 50px;
  padding: 9px 4px;
  gap: 16px;
  display: flex;
}

.MuiDialog-container .MuiPaper-rounded .titleText {
  font-size: 20px;
  font-weight: bolder;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.closeButton {
  width: 48px;
  height: 48px;
}

.MuiDialog-container .MuiPaper-rounded {
  height: 736px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 412px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.MuiDialogActions-spacing {
  position: sticky;
  bottom: 0;
  top: initial;
  background: #fff;
}

.MuiDialog-container .MuiPaper-rounded h2 {
  display: flex;
  align-items: center;
  padding: 10px 27px;
}

.MuiDialog-container .MuiPaper-rounded li .MuiListItem-gutters {
  align-items: flex-start;
}

.MuiDialog-container .MuiPaper-rounded li div {
  padding-left: 0;
  right: 0px;
}

.MuiDialog-container .MuiPaper-rounded li span.MuiTypography-root.MuiListItemText-primary {
  width: 139px;
  // height: 32px;
  color: #1d1b20;
  font-family: Roboto;
  font-size: 13px;
  // font-style: normal;
  // font-weight: 500;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.15px;
  margin-bottom: 4px;
}

.MuiDialog-container .MuiPaper-rounded li p {
  color: var(--M3-sys-light-on-surface, #1d1b20);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.25px;
}

.listItem {
  align-items: flex-start;
}

.MuiDialog-container .MuiPaper-rounded li p .MuiTypography-root {
  color: var(--M3-sys-light-on-surface, #1d1b20);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.25px;
}

/* For WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* For Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.MuiDialog-container .MuiDialogContent-root {
  padding: 0 13px 230px;
  overflow: auto;
  height: 100vh;
}

.MuiDialog-container .MuiPaper-rounded li img {
  width: 80px !important;
  height: 80px !important;
  border-radius: 12px;
  object-fit: cover;
  margin-right: 5px;
  box-shadow: 0 0 10px 1px #ddd;
}

.rate-price span {
  width: 100%;
  display: table;
}

.rate-price {
  margin-left: 145px;
  width: 57px;
  height: 32px;
  margin-top: -30px;
  min-height: 18px;
  display: flex;
  align-items: self-end;
}

.MuiDialog-container .MuiPaper-rounded li .MuiListItemSecondaryAction-root {
  top: 6px;
  transform: translate(0);
}

.price-div {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
}

.counter {
  display: flex;
  align-items: center;
  float: right;
  margin-left: auto;
  border-radius: 100px;
  color: #fff;
  height: 28px;
  width: 63px;
  justify-content: space-between;
}

.counter button {
  color: #fff;
  padding: 0;
}

.counter button svg {
  width: 14px;
  position: relative;
  left: 6px;
}

.counter button:first-child svg {
  width: 14px;
  position: relative;
  left: 6px;
}

.counter button:last-child svg {
  width: 20px !important;
  position: relative;
  left: -5px;
  height: 20px !important;
  color: #fff;
}

.MuiDialog-container .MuiPaper-rounded li .counter p {
  color: #fff;
  color: var(--M3-sys-light-on-primary, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

a.edit-btn {
  width: 100%;
  display: table;
  text-align: center;
  margin-top: 42px !important;
  color: #4f4940;
  max-width: 95%;
  margin-left: auto;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.15px;
  position: relative;
  left: -5px;
}

.MuiDialog-container .MuiDialogActions-root {
  justify-content: center !important;
  text-align: center;
  left: 7px;
  width: 100%;
  height: 52px;
  gap: 16px;
  margin-bottom: 10px;
}

.MuiDialog-container .MuiDialogActions-root h6 {
  width: 223px;
  height: 24px;
}

.rate-data div span {
  margin-left: -3px;
}

.rate-data div {
  display: flex;
  margin-bottom: 1px;
}

.MuiDialog-container .MuiPaper-rounded h6 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  margin-top: 32px;
  color: rgba(29, 27, 32, 1);
}

.MuiDialog-container .MuiPaper-rounded h2 button {
  margin-right: 0px;
}

.MuiDialog-container .MuiPaper-rounded h2 button svg {
  width: 33px;
  height: 28px;
}

.dialogActions .orderMore,
.dialogActions .checkout {
  width: 190px;
  height: 52px;
  border-radius: 100px;
  gap: 8px;
  display: flex;
  align-items: center;
}

.dialogActions .orderMore span,
.dialogActions .checkout span {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}

.menu__container_price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu__container_price_discount {
  margin-right: 8px;
  /* Fix the typo here */
}

.menu__container_price_nodiscount {
  width: 33px;
  height: 14px;
  position: relative;
}

.menu__container_price_original {
  width: 33px;
  height: 14px;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: grey;
    position: absolute;
    top: 9px;
    left: 0;
  }
}

.menu__container_price_discounted {
  width: 45px;
  height: 14px;
  color: #087813;
}

@media (max-width: 767px) {
  .MuiDialog-container .MuiPaper-rounded {
    margin: 0 !important;
    max-height: 100% !important;
    top: 0;
  }

  .dialogContent {
    width: 100% !important;
  }

  .MuiDialog-container .MuiPaper-rounded li span.MuiTypography-root.MuiListItemText-primary {
    width: 125px;
  }

  .rate-price {
    margin-left: 135px;
  }

  .MuiDialog-container .MuiPaper-rounded li img {
    width: 70px !important;
    height: 70px !important;
    margin-right: 0;
  }

  .dialogTitle {
    width: 100%;
    grid-gap: 8px;
    gap: 8px;
  }

  .dialogActions .orderMore span,
  .dialogActions .checkout span {
    font-size: 20px;
    text-transform: capitalize !important;
  }
}

.dialogActions .orderMore span,
.dialogActions .checkout span {
  font-size: 20px;
  text-transform: capitalize !important;
}