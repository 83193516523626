@import "../UI/mixin.scss";

.qrCode {
  &_container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &_header {}
  &_img {}
}