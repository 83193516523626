@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import "../UI/mixin.scss";

.headerContainer {
  display: flex;
  height: 50px;
  align-items: center;
  font-size: 25px;
  color: $relayfy-white;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
    // please don't change this to < 2
    z-index: 2;
}

.kioskHeader {
  @extend .headerContainer;
  justify-content: center;
}

.header {
  @extend .headerContainer;
  justify-content: space-between;



  .rewards {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &_right {
    display: flex;
  }

  .credit {
    margin: 0px 10px;
    font-size: 16px;
  }

  &_links {
    // font-family: Pacifico, cursive;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_icon {
    &:hover {
      color: $relayfy-white;
      text-decoration: underline;
    }

    color: white;
  }

  &_account {
    padding: 1rem;
    z-index: 1;
  }

  &_mapIcon {
    margin-left: 10px;

    &:hover {
      cursor: pointer;
    }
  }
}

.dropdown {
  .item {
    min-width: 100px;
    display: flex !important;
    justify-content: space-between;
  }
}

.locations {
  white-space: nowrap;
  font-size: 18px;
  font-weight: 900;

  .address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 8px;
  }

  .mapIcon {
    width: 24px;
    height: 24px;
  }
}
