@import "../UI/mixin.scss";

.title {
  display: flex;
  align-items: center;
  vertical-align: middle;
  font-size: 18px;

  &_icon {
    margin-right: 10px;
  }
}

.content {
  font-size: 16px;

  @include breakpoint(small) {
    // width: 100vw;
  }
}
