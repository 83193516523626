.progressBarContainer {
  position: relative;
  width: 100%;
  padding: 3% 5%;
  & .progressBar {
    position: relative;
    margin: 0 !important;
    height: 5vh;
    background-color: transparent !important;
    border: 1px solid #FFF !important;
    & .bar {
      background-color: transparent !important;
      height: 100%;
      &:after {
        animation: progress-active-overwite 2s ease infinite !important;
        background-color: rgb(255, 232, 102) !important;

      }
    }
    &.done .bar {
      background-color: rgb(91, 214, 91) !important;
      &:after {
        animation: progress-active 2s ease infinite !important;
      }

    }
  }

  & .progressStatus {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 49%);
    color: black;
    font-size: 150%;
    font-weight: 700;
    white-space: nowrap;

    &.withTime {
      bottom: 35%
    }
  }
}

@keyframes progress-active-overwite {
  0% {
    opacity: 1;
    width: 0;
  }

  100% {
    opacity: 1;
    width: 100%;
  }
}