@import '../../components/UI/mixin.scss';

$pagePadding: 2em;
$inputHeight: 40px;
$inputWidth: 40px;
$headerHeight: 50px;

.screenCenter {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.page {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: $relayfy-white;
  padding-left: $pagePadding;
  padding-right: $pagePadding;
  background: linear-gradient(0deg, #e6511b 0%, #feb74d 100%);
  // overflow-y: hidden;

  .body {
    padding-top: $headerHeight;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    .backBtnWrap {
      width: 100% !important;

      .backBtn {
        display: flex;
        align-items: center;
        gap: 12px;
        width: fit-content;
        font-size: large;
        font-weight: 900;
        padding: 12px;

        .backBtnIcon {
          font-size: 36px;
        }
      }
    }

    .form {
      width: 100%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      // @extend .screenCenter;

      .warningMessage {
        padding: 20px;
        text-align: center;
        color: black;
        background-color: white;
        border: 1px solid black;
        border-radius: 12px;
      }

      .inputRow {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        >* {
          width: 100%;

        }

        label {
          font-weight: bolder;
          font-size: larger;
        }

        .textInput {
          display: flex;
          align-items: center;
          height: $inputHeight;
          border-radius: 25px;
          color: #fea833;
          text-align: left;
          padding-left: 8px;
          border: none;
          font-size: 14px;

          &::placeholder {
            color: #fea833;
            opacity: 0.5;
            text-align: left;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .activateBtn:disabled {
        opacity: 0.6;
      }

      .activateBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bolder;
        font-size: larger;
        background-color: $relayfy-white;
        color: $relayfy-black;
        border: none;
        border-radius: 6px;
        width: 130px;
        height: 33px;
        padding: 6px 0;
        box-shadow: 1px 3px rgba(0, 0, 0, 0.3);
      }

    }

  }
}