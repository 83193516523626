@import "../UI/mixin.scss";

.rowWrapper {
  display: flex;
  flex-direction: row;  
  flex-wrap: nowrap;
  
  @include breakpoint(small) {
    font-size: 14px;
  }
  
  overflow-y: hidden;
  overflow-x: auto;
  max-width: 90vw;
  -webkit-overflow-scrolling: touch;
}

.row {
  padding: 5px 0px;
  margin-top: 1%;
}

.sliderWrap {
  padding: 8px 0px 8px 24px;
}

.accordion {

  .summaryRoot {
    min-height: 0px !important;
    padding-top: 12px !important;

    >* {
      margin: 0px 0px !important;
      margin-bottom: 8px !important;
      padding: 0px 0px !important;
    }

    .summary {
      display: flex;
      align-items: center;
      justify-content: center;

      >label {
        margin: 0px 12px  !important;

      }

      img {
        height: 32px;
      }
    }


    .expandIcon {
      // height: 10px;
    }
  }

  .details {
    display: unset;
  }

}

.card__content{
 background-color: $relayfy-lightgrey;
}

.menu {
  display: flex;
  margin: 5px 5px;
  min-height: 225px;
  font-weight: bold;
  // flex-direction: column;
  max-width: 200px;
  width: 142px;

  &__name {
    display: flex;
    align-items: baseline;
    margin: 11px 10px 8px 10px;
    font-weight: 800;
    font-size: 22px;

    &_counts {
      margin-left: 16px;
      white-space: nowrap;
      font-size: 12px;
      letter-spacing: 0;
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // width: 165px;
    height: 140px;
    overflow: hidden;
    perspective: 1000px;



    &_index {
      height: 100%;
      min-width: 24px;
      font-size: 8px;
      font-weight: bold;
      margin-left: 10px;
      &>p {
        font-size: 10px;
      }

      &_discountStar {
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
        border-radius: 25%;
        background-color: $relayfy-gold;
        color: $relayfy-white;
        width: 2em !important;
        height: 2em !important;
        padding: 3px;
      }
    }

    &_null {
      justify-items: right;
    }

    &_inner {
      position: relative;
      width: 100px;
      height:145px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    &_innerflip {
      transform: rotateY(180deg);
    }

    &_front,
    &_back {
      left: 0;
      top: 0;
      position: absolute;
      width: 100px;
      height: 150px;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    &_front_soldout {
      position: relative;

      & span {
        display: inline-block;
        width: 100%;
        color: white;
        font-size: 150%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        text-shadow: -1px 1px 0 #000,
          1px 1px 0 #000,
          1px -1px 0 #000,
          -1px -1px 0 #000;
      }

      & img,
      svg {
        filter: grayscale(100%);
      }

      & .no_image_soldout {
        // color: red;
        // color: #b9b9b9;
        width: 100px;

      }
    }

    &_back {
      background-color: white;
      transform: rotateY(180deg);
    }

    &_desc {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0 8px;
      font-size: 8px;
      line-height: 10px;
    }

    & img {
      width: 140px;
      height: 140px;
      border-radius: 8px;
    }

    &_arrow {
      position: relative;
      z-index: 3;
      font-size: 26px;
    }
  }
  

  &__option_label {
    width: 100%;
    padding: 0 20px;
    margin-top: 8px;
    text-align: left;
    font-size: 16px;

    @include breakpoint(small) {
      font-size: 14px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 70px;
    min-width: 40px;

    &_price {
      width: 100%;
      min-width: 20px;
      text-align: center;
      font-size: 12px;

      &_discount {}

      &_original {
        color: grey;
        position: relative;

        &:before  {
          content: "";
          display: block;
          width: 70%;
          height: 1.0px;
          background-color: grey;
          position: absolute;
          top: 62%;
          left: 60%;
          transform: translate(-50%, -50%) ;
        }
      }
      &_discounted {
        color: #087813; 
        margin-left: 8px;
      }
    }

    &_add {
      color: inherit;

      &_icon {
        font-size: 28px;
      }

      &_label {
        font-size: 11px;
        text-align: center;
      }
    }
  }

  &__description {
    flex-grow: 1;
    font-size: 12px;
    padding: 0 5px;
  }

  &__option {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    font-weight: bold;
  }
}
.menu__container {
  // display: flex;
  // align-items: center;
  font-family: Roboto;
font-size: 10px;
font-weight: 400;
line-height: 14px;
letter-spacing: 0.25px;
text-align: left;

}
.menu__containerlabel{
  width: 126px;
  height: 28px;
}
.menu__container_price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu__container_price_discount {
  margin-right: 8px; /* Fix the typo here */
}

.menu__container_add {
  display: flex;
  align-items: center;
  margin-top: 5px;

}
.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.menu__labelContainer {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // flex-grow: 1;
  font-family: Roboto;
font-size: 12px;
font-weight: 500;
line-height: 14px;
letter-spacing: 0.5px;
text-align: left;
margin-bottom: 5px;
display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  min-height: 28px;
}

// .menu__label {
//   white-space: normal; /* Allow text to wrap */
//   overflow: hidden;
//   text-overflow: ellipsis;
//   font-family: 'Lato';
//   font-weight:500;
//   size: 12px;
//   line-height: 14px;
//   letter-spacing: 0.5px;
// }

.MenuCard_card, .card__content {
  height: 250px;
  padding: 0 !important;
}
li.menu div {
  // width: 100%;
}
.card__content {
  width: 142px;
  // height: 100%;
  height: 225px;
  border: 1px;
}
.menu__picture, .menu__picture_inner, .menu__picture_front, .menu__picture_front_soldout
{
  width: 100%;
}
.menu__picture
{
  position: relative;
}
// .menu__picture span,
.menu__picture span.card_tag,
.menu__picture span.menu__container_price_nodiscount,
.menu__picture span.menu__container_price_discount,
.menu__picture span.menu__container_price_discounted {
  width: 80px;
  height:24px;
  position: absolute;
  top: 3px;
  left: 0;
  background: #2B8000;
  z-index: 1;
  color: #fff;
  // padding: 6px 10px 4px;
  font-size: 12px;
  font-family: Roboto ;
  border-radius: 0px 16px 16px 0px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  
}
.menu__picture span img {
  max-width: 19px;
  margin-right: 4px;
}
.menu__option {
  padding: 3px 14px 11px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.menu__option div {
  align-items: flex-start;
}
.menu_outer_lay{
  width: 126px;
  height:40px;
}
.menu__container_add {
  display: table;
  margin-left: auto !important;
  float: right;
  width: 100% !important;
  text-align: right;
  margin: 0;
}
.menu__container_price_nodiscount{
  width: 45px;
  height: 14px;
}
.menu__container_price_original{
  width: 33px;
  height: 14px;
}
.menu__container_price_discounted{
  width: 45px;
  height: 14px;
}
.menu__option svg
{
  font-size:40px !important;
}
h6.menu__option div:nth-child(2) {
  display: flex;
  align-items: center;
}

.tag{
  width: 11.67px;
  height: 11.67px;
  top: 1.17px;
  left: 1.17px;
}
.MuiSnackbarContent-root {
  max-width: 200px;
}