@import "../UI/mixin.scss";

.menu-slider {
  display: flex;
  flex-direction: row;  
  flex-wrap: nowrap;
  
  @include breakpoint(small) {
    font-size: 14px;
  }
  
  overflow-y: hidden;
  overflow-x: auto;
  max-width: 90vw;
  -webkit-overflow-scrolling: touch;

  .slick-arrow {
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .slick-next {
    right: 2%;
  }

  .slick-prev {
    z-index: 1;
    left: 2%;
  }

  .slick-slide {
    margin: 0 auto;
  }

}
  .slick-slider{
    touch-action: auto !important;
  }

.slick-disabled {
  opacity: 0;
}
.MuiSnackbarContent-root {
  max-width: 200px;
}
.MuiSnackbar-root {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  top: 60px;
  bottom: inherit !important;
}

