@import "../UI/mixin.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;

  .borderDiv {
    border-radius: 8px;
    border: 4px red solid;
    width: 420px;
    max-width: 100%;
    text-align: center;
    overflow: hidden;
    box-shadow: 7px 7px 5px -5px rgba(0, 0, 0, 0.43);
    padding: 0 6px;
    padding-bottom: 24px;


    .logo {
      margin-top: -16px;
      width: 100px;
      height: 100px;
    }

    .content {
      font-size: 18px;

      @include breakpoint(small) {
        // width: 100vw;
      }
    
    }
  }
}

.title {
  vertical-align: middle;
  font-size: 18px;

  &_icon {
    margin-right: 10px;
  }
}

