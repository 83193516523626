@import "../UI/mixin.scss";

.container {
  width: 100%;
  margin: 0 -8px;

  .label {
    text-align: left;
    margin: 4% 0 1% 0;
  }

  .inputs {
    border-radius: 50px;
    height: 35px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-self: center;
    position: relative;
    padding-left: 15px;
    margin-bottom: 10px;

    .textInput {
      display: block;
      height: 100%;
      line-height: unset;
      color: #fea833;
      text-align: left;
      padding-left: 8px;
      border: none;
      width: 100%;
      font-size: 14px;

      &::placeholder {
        color: #fea833;
        opacity: 0.5;
        text-align: left;
      }

      &:focus {
        outline: none;
      }
    }

    button {
      width: 46px;
      height: 33px;
      padding: 0 0;
      margin: 0 0;
      background-color: #fea833;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      margin-top: 1px;
      margin-right: 1px;
      color: #fff;
      border: none;
      transition: opacity 0.5s;

      &:disabled {
        opacity: 0.5;
      }
    }

    .inputError {
      @extend .errorBase;
    }
  }
}