@import "../UI/mixin.scss";

.creditPay {
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

#form-container {
  position: relative;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  border: 1px solid black;
  border-radius: 15px;
}

#card-container {
}

.third {
  float: left;
  width: calc((100% - 32px) / 3);
  padding: 0;
  margin: 0 16px 16px 0;
}

.third:last-of-type {
  margin-right: 0;
}

/* Define how SqPaymentForm iframes should look */
.sq-input {
  height: 56px;
  box-sizing: border-box;
  border: 1px solid #e0e2e3;
  background-color: white;
  border-radius: 6px;
  display: inline-block;
  -webkit-transition: border-color 0.2s ease-in-out;
  -moz-transition: border-color 0.2s ease-in-out;
  -ms-transition: border-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out;
}

/* Define how SqPaymentForm iframes should look when they have focus */
.sq-input--focus {
  border: 1px solid #4a90e2;
}

/* Define how SqPaymentForm iframes should look when they contain invalid values */
.sq-input--error {
  border: 1px solid #e02f2f;
}

#sq-card-number {
  // margin-bottom: 16px;
}

/* Customize the "Pay with Credit Card" button */
.button_credit_card {
  width: 100%;
  height: 56px;
  margin-top: 0px !important;
  background: #000000;
  border-radius: 56px;
  cursor: pointer;
  display: block;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: center;
}

.button_credit_card:hover {
  background-color: #4281cb;
}

.buttonBase {
  display: block;
  margin: 20px auto;
  border-radius: 5px;
  height: 50px;
  width: 300px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  color: $relayfy-black;
  background-clip: border-box;
  background-position: center;
  background-color: $relayfy-white;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
}

.creditForm {
  .googlePay {
    @extend .buttonBase;

    > div {
      display: none;
    }

    & div button div {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .applePay {
    @extend .buttonBase;
  }

  .icon {
    margin-bottom: 6px;
  }
}

.stripeForm {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
}
