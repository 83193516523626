.loader{
	position: fixed;
	width:100%;
	height:100%;
	overflow: hidden;
	display:flex;
	justify-content: center;
	align-items: center;
	background:rgba(255,255,255,0.5);
	z-index:10;
	left:0;
	top:0;
}