@import "../UI/mixin.scss";

.buttonWrapper {


    @include breakpoint(small) {
        width: 96%;
        font-size: 19px;
    }

    .label {
        font-weight: bold;
        margin-right: 8px;
        font-size: 16px;
        word-wrap: normal;
        white-space: nowrap;

        @include breakpoint(small) {
            font-size: 14px;
        }
    }
}
