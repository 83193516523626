@import '../UI/mixin.scss';

.errorMsg {
  @extend .errorBase;
  text-align: center;
}

.container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  outline: none;
  border: 1px solid;
  max-width: 600px;
  @include breakpoint(small) {
    max-height: 98vh;

  }
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  overflow: hidden;
  overflow-y: visible;

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: calc(100% - 100px);
    padding: 20px 20px 0px;
    overflow-y: auto;

    .dropdownsSection {
      display: flex;
      flex-direction: column;
      align-items: start;

      .inputCombo {
        padding: 15px 12px;
        display: grid;
        grid-template-columns: 128px 1fr;
        margin-left: 5px;

        .requiredIndicator {
          color: red;
          font-weight: bold;
        }

        .errorInput {
          background-color: #fce4e4;
          border: 1px solid #cc0033;
          outline: none;
        }

        select,
        option.selected {
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .label {
          font-weight: bold;
          margin-right: 8px;
          font-size: 16px;
          word-wrap: normal;
          white-space: nowrap;
          text-align: left;

          @include breakpoint(small) {
            font-size: 14px;
          }
        }

        .input {
          border: none;
          border-bottom: 1px solid #999;
          padding-bottom: 4px;
          font-size: 16px;

          @include breakpoint(small) {
            font-size: 14px;
          }
        }

        &_error {
          @extend .errorBase;
        }
      }
    }

  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    gap: 15px;

    @include breakpoint(small) {
      justify-content: space-between;
      margin: 4px 0;
    }

    .button {
      &:disabled {
        opacity: 0.6;
        pointer-events: none;
      }

      border-radius: 4px;
      background-color: white;
      width: 130px;
      margin: 0 5%;
      padding: 8px 12px;
      border: none;
      box-shadow: 7px 7px 5px -5px rgba(0, 0, 0, 0.43);
      font-weight: 700;

      @include breakpoint(small) {
        margin: 0 auto;
      }
    }
  }

  .title {
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    color: #fff;
    height: 50px;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-weight: bold;

    .label {
      display: flex;
      align-items: center;
      font-size: 120%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      height: 50px;
      padding: 10px 20px;
      font-weight: bold;
      white-space: nowrap;
    }

    &_closeButton {
      font-size: 24px;
      padding: 10px 20px;
    }
  }
}