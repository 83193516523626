$blue: #65C3EA;
$white: white;

.page {

  height: 100%;
  input,
  textarea {
    text-align: left;
    border-radius: 8px !important;
  }
  .backBtn {
    display: flex;
    align-items: center;
    gap: 12px;
    width: fit-content;
    font-size: large;
    font-weight: 900;
    padding: 12px;
  }
  .title {
    width: 100%;

    text-align: center;
    padding: .7em 0;

    h4 {
      font-size: 24px;
      color: white;
      margin-block-start: 1.33em;
      margin-block-end: 1.33em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      margin: 0.6em 10% 0.8em;
      line-height: 1.3;
    }
  }

  .comboBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 5%;

    >label {
      text-align: left;
      color: white;
      margin: 10px 0;
      font-size: 140%;
    }

    input {
      background-color: white;
    }

    >textarea {
      max-height: 60px;

      font: inherit;
      color: currentColor;
      height: 1.1876em;
      margin: 0;
      display: block;
      padding: 18.5px 14px;

      border-color: rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;

      box-sizing: content-box;
      animation-name: mui-auto-fill-cancel;
      letter-spacing: inherit;
      animation-duration: 10ms;
      -webkit-tap-highlight-color: transparent;
    }
  }

  >.body {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap:2rem;
    padding:0 1.5rem;
    max-width: 700px;
    margin: 0 auto;

    >* {
      margin-top: 18px;
    }

    .error {
      color: rgb(240, 0, 0);
      font-size: 20px;
      font-weight: 900;
      font-style: italic;
    }
  }

  .back {}

  .modes {

    .default {
      color: $blue;
      background-color: $white;
    }

    .active {
      color: $white;
      background-color: $blue;
    }
  }

  .email {
    @extend .comboBox;
  }

  .sms {
    @extend .comboBox;
  }

  .name {
    @extend .comboBox;
  }

  .note {
    @extend .comboBox;
  }

  .actions {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .buttonBase {
      padding: 10px 20px;
      margin: 0 20px;
      width: 160px;
      font-size: 130%;
      font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    }

    .back {
      @extend .buttonBase;
      background-color: $white;
      color: $blue;
    }

    .submit {
      @extend .buttonBase;
      background-color: $blue;
      color: $white;
    }
  }
}