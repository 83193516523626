@import "../UI/mixin.scss";

.categoryTitle {
  display: flex;
  align-items: baseline;
  margin: 32px 34px 16px;
  font-weight: 800;
  font-size: 36px;
  line-height: normal;
}

.category__options {
  background-color: $relayfy-lightgrey;
  display: 'flex';
  justify-content: 'space-between';
  align-items: 'center';
  margin-top: 4%;
  position: sticky;
  top: 49px;
  z-index: 1;
}

.category__options img {
  max-width: 28px;
  margin: 0 auto;
}

.menuContainer {
  padding-top: 64px;
  display: block;

  &:nth-child(even) {
    background-color: #fffae5;
  }

  .header {
    position: relative;
    width: 100%;
    background-color: $relayfy-white;
    z-index: 1;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      font-size: 25px;
      z-index: -1;
      margin: 0 1%;
      margin-bottom: 10px;
      font-weight: 900;
      left: 0;
      right: 0;
      text-align: center;

      .businessName {
        font-size: 22px;
      }

      >img {
        border-radius: 10px;
        margin-right: 1%;
      }
    }

    .location {
      display: flex;
      min-width: 100px;
      white-space: nowrap;
      font-size: 18px;
      margin-top: 8px;

      .address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 8px;
      }

      .mapIcon {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
  }

  &__description {
    flex-grow: 1;
    font-size: 12px;
  }

  &__image {
    width: 65px;
    height: 65px;
    border-radius: 15px;
  }

  &__add {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 8px;

    &_label {
      font-size: 10px;
    }

    &_icon {
      font-size: 24px;
    }
  }
}

@media (max-width:991px) {

  .category-slider.slick-slider {
    position: static;
  }

  .category-slider .slick-prev {
    top: 30px !important;
    left: 11px !important;
    transform: translate(0) !IMPORTANT;
  }

  .category-slider .slick-next {
    left: initial;
    right: 11px !important;
    top: 17px !important;
    transform: translate(0) !IMPORTANT;
  }

  .title {
    font-size: 23px !important;
  }

  .location {
    font-size: 13px !important;
  }
}