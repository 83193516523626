@import "../UI/mixin.scss";

.flexCenterColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pageContent {
  width: 100%;
  height: 100%;
  font-weight: 900;
  text-align: center;

  @extend .flexCenterColumn;
  justify-content: space-evenly;
  font-size: 4vh !important;
  line-height: 38px;
  // gap: 10%;

  @include breakpoint(medium) {
    @media only screen and (orientation:landscape) {
      // font-size: 300% !important;
      // gap: 5%;
    }
  }


  >* {
    @extend .flexCenterColumn;
    gap: 1vh;
    .value {
      font-size:  6vh !important;
    }
  }

  .title {
    z-index: 2;
    margin: 1vh;
  }

  .message {
    justify-content: space-between;
    max-height: 60%;
    max-width: 40vh;
    font-size: 2vh;
  }

  .footer {
    z-index: 2;

    .callToAction {
      font-size: 5vh !important;
      font-weight: bold;
      width: fit-content;
    }

  }
}