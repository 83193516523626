@import "../UI/mixin.scss";

.popup {
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  outline: none;
  border: 1px solid;
  max-width: 600px;
  max-height: 800px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  overflow: hidden;

  &_container {
    z-index: 2;
  }

  @include breakpoint(small) {
  }
}

.body {
  min-width: 280px;
  height: 100%;

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: calc(100% - 100px);
    max-height: 666px;
    padding: 20px 20px 0px;
    overflow-x: auto;
  }

  &_footer {
    width: 100%;
    text-align: center;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .label {
      display: flex;
      align-items: center;
      font-size: 22px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      height: 50px;
      padding: 10px 20px;
      font-weight: bold;
      white-space: nowrap;
    }

    .closeButton {
    }
  }
}

.okayFooter {
  text-align: center;
  margin: 4% auto;
  width: 100%;

  .separator {
    margin-bottom: 0;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
  }
}
