@import '../../components/UI/mixin.scss';

$pagePadding: 2em;

.account {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  color:black;
}

.content {
  width: 100%;
  max-width: 320px;
}