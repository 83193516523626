@import '../UI/mixin.scss';

.bodyContainer {}

.buttons {
  text-align: center;
  width: 100%;
  padding: 8px 12px;
  border-top: 1px solid black;
  border-top-width: thin;
  font-weight: 700;
  color: $relayfy-white !important;

  & button {
    margin: 0px 10px;
  }

  &_no {
    background-color: $relayfy-grey !important;
    color: $relayfy-white !important;
  }

  &_yes {
    color: $relayfy-white !important;
  }
}