.ui.dimmer {
  background-color: rgba(255, 255, 255, 0.35) !important;
}

.modal {
  max-width: 600px;

  & .header,
  & .content {
  }
  & .header {
    background-color: #fea834 !important;
    color: #fff !important;
  }
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  margin: 16px 0;

  .noButton {
    color: #000 !important;
  }
  // }
}

@media (min-width: 760px) {
  .header,
  .content {
    font-size: 2vw !important;
  }
  .buttons {
    & .button {
      height: 100% !important;
      font-size: 1.5vw !important;
    }
  }
}

@media (min-width: 1439px) {
  .header,
  .content {
    font-size: 1vw !important;
  }

  .content {
    padding: 5% 7% !important;
  }

  .buttons {
    & .button {
      font-size: 1vw !important;
    }
  }
}
