.category-slider {

    .slick-arrow {
      display: flex !important;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      top: 40%;
      transform: translateY(-40%);
    }
  
    .slick-next {
      right: 2%;
    }
  
    .slick-prev {
      z-index: 1;
      left: 2%;
    }
  
    .slick-slide {
      margin: 0 auto;
    }
  
  }
  
  .slick-disabled {
    opacity: 0;
  }
  @media (max-width:767px)
  {
    .category-slider.slick-slider {
      position: static;
    }
    .category-slider .slick-prev {
      top: 17px !important  ;
      left: 11px !important;
      transform: translate(0) !IMPORTANT;
    }
    .category-slider .slick-next {
      left: initial;
      right: 11px !important;
      top: 17px !important  ;
      transform: translate(0) !IMPORTANT;
    }
  }
  
  