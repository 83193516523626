.cart-slider {



  .slick-slide {

    &>div {
      width: 100%;
    }
  }

}