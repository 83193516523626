@import "../../mixin.scss";

.orderTypeSelection {
  padding: 0 6%;

  display: flex;
  flex-direction: column;
  gap: 15px;

  .card {
    display: flex;
    background-color: #fef7ff;
    padding-left: 5%;

    .cardMedia {
      height: 100%;
      margin: 16px 2%;
      .cardIcon {
        width: 35px;
        height: 35px;
      }
    }
    .cardTitle {
      font-size: 16px;
    }

    .cardContent {
      width: 100%;
      text-align: left;

      p {
        font-size: 14px;
      }
    }

    .cardDescription {
      margin-left: 5%;
    }
  }
}

.promoText {
  color: green;
}

.sheet {
  font-size: 16px;
  overflow: hidden;
  width: 80%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column;
  max-width: 412px;
  max-height: 90%;
  gap: 32px;
  position: relative;

  @include breakpoint(small) {
    width: 100%;
    height: 100vh;
    max-height: unset;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    column-gap: 2%;
    padding: 10px 20px;

    .title {
      font-weight: bold;
      color: #fff;
    }
  }

  .icon {
    font-size: 24px;
  }

  .content {
    height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-flow: column;
    padding-bottom: 48px;

    .footer {
      position: relative;
      flex: 1;
      margin-top: 20px;

      @include breakpoint(small) {
        position: fixed;
        bottom: 24px;
        flex: unset;
        margin: unset;
        width: 100vw;
      }
    }
  }
}
