@import '../UI/mixin.scss';


.container {
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 6px;
  margin: 30px 0%;

  .dateTime {
    font-size: larger;
    font-weight: 900;
  }

  .button {
    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    border-radius: 4px;
    color: white;
    width: 68px;
    padding: 8px 12px;
    border: none;
    box-shadow: 7px 7px 5px -5px rgba(0, 0, 0, 0.43);
    font-weight: 700;
    display: flex;
    justify-content: center;

    @include breakpoint(small) {
      margin: 0 auto;
    }
  }
}