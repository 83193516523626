.body {
  width: 100vw;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 6%;
    justify-content: space-evenly;
    gap:1rem;
    .title {
      color: inherit;
    }

  
    .radio {
      width: 90%;
    }
  
    .feedback {
      margin-top: 5%;
      width: 100%;
      &_text {
        display: inherit;
      }
      &_title {
        font-weight: bold;
        transform: translateX(-5%);
        margin-bottom: 4%;
      }
      &_feedback {
        & textarea {
          resize: none;
        }
      }
      &_buttons {
        display: flex;
        justify-content: space-between;
        font-size: 130%;
        & div {
          text-align: center;
          padding: 4% 10%;
          margin-top: 2%;
          border-radius: 10px;
          &:nth-child(1) {
            color:#65C3EA;
            background-color: #FFF;
          }
          &:nth-of-type(2) {
            color: #FFF;
            background-color: #65C3EA;
          }
        }
        
        .submit {
          color: inherit;
        }

        & a {
          color: unset;
        }
      }
    }
  }
}
