.modal {
	display: "flex";
	align-items: "center";
	justify-content: "center";
}

.paper {
	background-color: #fff;
	max-width: 500px;
	width: 80%;
	margin: 15% auto;
	border-radius: 10px;
	overflow: hidden;

	.title {
		margin: 0 0;
		height: 50px;
		line-height: 50px;
		color: #fff;
	}

	.content {
		text-align: center;
		margin: 4%;
	}

	&_buttonGroup {
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		background-color: #fff;
		height: 50px;
		gap: 1rem;
		padding: 8px 0 !important;
		margin: 0 0;

		button {
			width: 100%;
			height: 100%;
			border: none;
			background: transparent;
			font-size: 14px;
			font-weight: 800;
			font-size: 14px;

			&:first-child {
				border-right: 1px solid #e1e1e1;
			}
		}
	}

	&_cancel {
		color: #999;
	}

	&_ok {
		color: #fea833;
	}
}