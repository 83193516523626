.backBtn {
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  font-size: large;
  font-weight: 900;
  padding: 12px;
  position: fixed;
  top: 50px;
  .backBtnIcon {
    font-size: 36px;
  }
}

.trackingButtonBase {
  color: #fea833;
  background-color: #fff;
  font-size: 160%;
  border-radius: 20px;
}

.body {
  width: 100%;
  min-height: 100vh;

  .wrapper {
    width: 100%;
    max-width: 600px;
    margin: 0 auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    padding: 122px 10%;

    @media (max-width: 480px) {
      // padding-top: 120px;
    }

    .order {
      width: 100%;
      margin: 0;
    }

    .imHere {
      @extend .trackingButtonBase;
      margin-top: 20px;
      cursor: pointer;
      z-index: 10;
    }

    .recommendUs {
      @extend .trackingButtonBase;
      display: block;
      margin: 20px 0 0;
    }

    .readyTime {
      font-size: 90%;
      color: #000;
      text-align: right;
      transform: translateY(60%);
    }

    .info {
      width: 90%;
      margin-top: 4%;
      color: gray;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 10px;

      &__detail {
        padding: 0 4%;
        display: flex;
        justify-content: space-between;

        &:first-child {
          border-bottom: 1px solid white;
        }
      }
    }

    .message {
      width: 100%;

      .readyIn {
        transform: translateY(80%);

        & + h3 {
          margin-bottom: 20px;
        }
      }
    }

    .time {
      width: 100%;
      font-size: 130%;
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: #eee;
      gap: 1rem;
      font-size: 20px;
      cursor: pointer;

      & span {
        border-bottom: 2px solid #eee;
      }
    }
  }

  .user {
    &_title {
      text-align: center;
      margin-top: 10px;
    }

    &_warning {
      width: 90%;
      border-radius: 10px;
      font-size: 14px;
      background-color: rgba(0, 0, 0, 0.3);
      margin: 0 auto;
      padding: 10px 14px;
    }
  }

  .nonuser {
    width: 90%;

    &_title {
      text-align: left;
      margin: 4% 0 1% 0;
    }

    &_input {
      border-radius: 50px;
      height: 35px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-self: center;
      position: relative;
      padding-left: 15px;
      margin-bottom: 10px;

      input {
        display: block;
        height: 100%;
        line-height: 50px;
        color: #000;
        text-align: left;
        padding: 0 10px;
        border: none;
        width: 100%;
        font-size: 14px;

        &::placeholder {
          color: #000;
          opacity: 0.5;
          text-align: left;
        }

        &:focus {
          outline: none;
        }
      }

      button {
        width: 46px;
        height: 33px;
        padding: 0 0;
        margin: 0 0;
        background-color: #fea833;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        margin-top: 1px;
        margin-right: 1px;
        color: #fff;
        border: none;
        transition: opacity 0.5s;

        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }

  .text_input {
    line-height: unset !important;
  }
}

.paper {
  background-color: #fff;
  max-width: 500px;
  width: 80%;
  margin: 15% auto;
  border-radius: 20px;
  overflow: hidden;

  h2 {
    font-size: 18px;
    margin: 0 0;
    height: 50px;
    line-height: 50px;
    background: #fea833;
    padding: 0 15px;
    color: #fff;
  }

  &_content {
    text-align: center;
  }

  &_buttonGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: #fff;
    border-top: 1px solid #e1e1e1;
    height: 50px;
    margin: 0 0;

    button {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      font-size: 14px;
      font-weight: 800;
      font-size: 14px;

      &:first-child {
        border-right: 1px solid #e1e1e1;
      }
    }
  }

  &_cancel {
    color: #999;
  }

  &_ok {
    color: #fea833;
  }
}

.errHeader {
  display: flex;
  align-items: center;
  padding: 10px 20px 10px;
  background: #fea833;
  height: 40px;
}

.modalBody {
  width: 100%;
  display: flex;
  justify-content: center;

  &_checkbox {
    margin-left: 16px;
  }
}

.emailError {
  width: 100%;
  text-align: center;
}
