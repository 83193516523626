@import "../UI/mixin.scss";

.sheet {
    position: absolute;
    font-size: 16px;
    overflow: hidden;
    background-color: white;
    display: flex;
    flex:1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    top: 0;
    left:0;
    z-index: 150;
    width: 100%;
    height: 100%;
  
    @include breakpoint(small) {
      width: 100%;
      height: 100vh;
      max-height: unset;
    }
  
    .header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 50px;
      column-gap: 2%;
      padding: 10px 20px;
  
      .title {
        font-weight: bold;
        font-size: 1.4rem;
        color: #fff;
      }
    }
  
    .icon {
      font-size: 24px;
    }
  
    .content {
      height: 100%;
      overflow: auto;
      width: 100%;
      display: flex;
      flex-flow: column;
      padding-bottom: 48px;
  
      .footer {
        position: relative;
        flex: 1;
        margin-top: 20px;
  
        @include breakpoint(small) {
          position: fixed;
          bottom: 24px;
          flex: unset;
          margin: unset;
          width: 100vw;
        }
      }
    }
  }
  