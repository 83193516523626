@import '../../components/UI/mixin.scss';

$blue: #65C3EA;
$white: white;

$gapSpace: 18px;

.page {
  // height: 100%;
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  padding-top: 24px;
  padding-bottom: 24px;


  .bizInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    font-weight: 900;

    h3 {
      margin-top: 12px;
    }
  }

  .buttons {
    width: 33%;
    min-width: 300px;
    max-width: 400px;

    @include breakpoint(medium) {
      width: 60%;
    }


    @include breakpoint(small) {
      width: 90%;
      max-width: unset;
    }


    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    row-gap: $gapSpace;

    .button {
      color: white;
      height: 44px;
      width: 40%;

      @include breakpoint(small) {
        width: 90%;
      }
    }
  }

  .card {
    border-style: solid;
    border-width: 1px;
    width: 100%;


    .header {}

    .content {
      .location {
        font-weight: bold;
      }
    }

    .actions {
      .button {
        width: 100%;
      }
    }
  }

}