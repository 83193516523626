@import "../UI/mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

.banner_background {
  width: 100%;
  min-height: 200px;

  position: absolute;
  z-index: -1;

  @include breakpoint(small) {
    background: url("../../img/bannerImage.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: -40px;
  }

}

.banner {
  z-index: 1;
  margin-bottom: 20px;

  &_title {
    text-align: center;
    margin: .5em 0 !important;
  }

  &_popularTags {
    margin: 20px 5%;

    &_label {
      margin-bottom: .3em;
    }

    &_tag {}

  }
}

.highContrastBtn {
  width: 80%;
  color: $relayfy-white !important;
  background-color: $relayfy-black !important;
}

.drawer {
  &_header {
    text-align: right;
  }

  &_list {
    text-align: center;
  }

  &_signIn {
    @extend .highContrastBtn;
  }
}

.labelsAutocomplete {

  &_wrap {
    display: flex;
    justify-content: center;
  }

  &_input {
    width: 90%;
  }
}

.toolbar {

  display: flex;
  justify-content: space-between;

  min-height: unset;

  &_sticky {
    //background black, white text
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;

  }

  &_title {
    width: 100%;
    color: $relayfy-white;
    font-weight: 700;
  }

  &_logo {
    width: 100px;
  }

  &_menuButton {
    // color: $relayfy-white;
  }
}

$cardImageSize: 150px;

.card {
  display: flex;
  flex-direction: column;
  
  font-family: 'Lato' !important;
  
  margin-bottom: 20px !important;
  border-radius: 10px;

  @include breakpoint(small) {
    width: auto;
  }

  &_media {
    margin-top: 10px !important;

    display: flex !important;
    justify-content: center !important;

    &_img {
      border-radius: 25%;
      width: $cardImageSize !important;
      height: $cardImageSize;
    }
  }

  &_content {
    height: 100%;
    padding-bottom: 0px !important;
  }

  &_rating {
    float: right;
  }

  &_tagline {
    margin-top: 10px !important;
  }

  &_actions {
    display: flex;
    justify-content: space-between;

    .recommendIcon{
      width: 40px;
      height: 40px;
    }
  }
}

.rewardProgress {
  float: right;

  &_rewardsIcon {
    color: #ffcc06;
    margin-bottom: 15px;
    margin-right: 15px;
  }
}

.rewardsRows {
  margin-top: 10px;
}

.noBizFound {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  text-align: center;

  &_labels {
    margin: 10px 0;
  }
}

.aboutUs {

  &>* {
    margin-bottom: 20px;
  }

  &_text {}

  &_contacts {}
}