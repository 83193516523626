@import "../UI/mixin.scss";

.selectBase {
  width: fit-content;
}

.baseButton {
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  border-radius: 4px;
  background-color: white;
  width: 130px;
  margin: 0 5%;
  padding: 8px 12px;
  border: none;
  box-shadow: 7px 7px 5px -5px rgba(0, 0, 0, 0.43);
  margin-top: 20px;
  font-weight: 700;

  @include breakpoint(small) {
    margin: 0 auto;
  }
}

.baseLabel {
  font-weight: bold;
  margin-right: 8px;
  font-size: 16px;
  word-wrap: normal;
  white-space: nowrap;

  @include breakpoint(small) {
    font-size: 14px;
  }
}

.baseTextField {
  font-weight: bold;
  margin-right: 8px;
  font-size: 16px;
  word-wrap: normal;

  border: none;
  border-bottom: 1px solid #999;
  padding-bottom: 1px;

  @include breakpoint(small) {
    font-size: 14px;
  }
}

.errorText {
  @extend .errorBase;
}

.warningText {
  @extend .warningBase;
}

.errorInput {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

.validInput {
  background-color: #7aff85af;
  border: 1px solid #00ff4c;
  outline: none;
}

.baseInputCombo {
  display: flex;
  align-items: flex-end;

  > .label {
    @extend .baseLabel;
  }

  > .textField {
    @extend .baseTextField;
  }

  .error {
    @extend .errorText;
  }
}

.checkout {
  flex: 6;

  @include breakpoint(small) {
    flex: unset;
  }

  .tableTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 700;
    font-size: 22px;
  }

  .body {
    height: 100%;
    max-width: 500px;
    min-height: 50%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }

  .buttonGroupWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .buttonGroup {
    text-transform: capitalize;
    font-weight: 700;
    color: #333;

    @include breakpoint(small) {
      > button {
        padding: 8px 16px;
      }
    }

    & * {
      font-size: 12px;
      text-transform: none !important;
    }
  }
}

.sendSmsSwitch {
  // padding-left: 5px;
}

.form {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: calc(100% + 100px);
  padding: 0 20px 60px;
  background-color: white;

  gap: 8px;

  .formRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .dropdownContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
    gap: 24px;

    .dropdownRow {
      display: flex;

      .label {
        @extend .baseLabel;
      }

      .select {
        // width: 200px;
      }
    }
  }

  .textFieldHalf {
    width: 46%;
    min-width: 120px;
  }

  .textField {
    width: 100%;
  }

  @include breakpoint(small) {
    font-size: 14px;
  }

  &__optionalSmsLabel {
    font-weight: bold;
  }

  .notes {
    > .label {
      @extend .baseLabel;
      background: #fff;
    }

    .textField {
      @extend .baseTextField;
      height: 42px;
      max-height: 100px;
      padding: 15px 12px;
      resize: none;
      width: 100%;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
  }

  &_paperBag {
    display: flex;
    gap: 10px;

    .label {
      @extend .baseLabel;
    }

    .select {
      @extend .selectBase;
    }
  }

  > .inputCombo {
    @extend .baseInputCombo;

    > .label {
      @extend .baseLabel;
    }

    > .textField {
      @extend .baseTextField;
    }

    .error {
      @extend .errorBase;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 50%;
  padding: 16px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > .buttonWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button {
      width: 50%;
      height: 50px;
      font-weight: 900;
      font-size: 18px;
    }

    & div {
      display: inline-block;
    }

    //
    margin: 15px 0;

    @include breakpoint(small) {
      justify-content: space-between;
      margin: 16px 0;
    }

    .button {
      @extend .baseButton;
    }
  }
}

.promo {
  width: 100%;
  gap: 16px;

  @extend .baseInputCombo;

  .label {
    @extend .baseLabel;
  }

  .textField {
    @extend .baseTextField;
    width: 128px;
  }

  .validIcon {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 10px;
  }

  .validateBtn {
    max-width: 120px !important;
    margin-left: 4px !important;
    padding: 6px 16px !important;

    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    .label {
      font-weight: 700;
      text-transform: none;
    }
  }
}

.requiredIndicator {
  color: red;
  font-weight: bold;
}

.deliveryFields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  .textField {
    width: 100%;
  }
}
