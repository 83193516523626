@import "../UI/mixin.scss";

.flexCenterColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.kioskPopupContent {
  width: 100%;
  height: 100%;
  font-weight: 900;
  text-align: center;

  @extend .flexCenterColumn;
  justify-content: space-evenly;
  font-size: 4vh !important;
  line-height: 38px;

  @include breakpoint(medium) {
    @media only screen and (orientation: landscape) {
      // font-size: 300% !important;
      // gap: 5%;
    }
  }

  > * {
    @extend .flexCenterColumn;
    gap: 2vh;

    .value {
      font-size: 6vh !important;
    }
  }

  .orderNum {
  }

  .payableAmt {
  }

  .message {
    font-size: initial;
  }

  .footer {

    .callToAction {
      font-size: 5vh !important;
      font-weight: bold;
      width: fit-content;
    }

    .autoCloseTime {
      font-size: 3vh !important;
      font-weight: unset;
      line-height: 36px;
    }
  }
}

.pay_in_person {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  // overflow-y: auto;

  .price {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 60px 35px;
    row-gap: 16px;
    column-gap: 8px;
    padding-bottom: 3px;
    color: #000000;
    font-size: 15px;
    margin-bottom: 3px;

    @include breakpoint(small) {
      width: 253px;
    }

    .label {
      text-align: left;
      color: inherit;
      .weight {
        color: inherit;
        font-weight: bold;
      }
    }

    .value {
      text-align: right;
      color: inherit;
      text-wrap: nowrap;
    } 

    .rightText {
      text-align: left;
    }
  }

  &__total {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    padding: 0 44px 44px;
    gap: 44px;


    button + button,
    div + button {
    }

    .buttonBase {
      width: 300px;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 12px 17px;
      font-weight: 700;
    }

    .creditCardPayBack {
      @extend .buttonBase;
      background-color: #ccc;
    }

    &_edit {
      @extend .buttonBase;
      background-color: #ccc;
    }

    &_creditCardPay {
      @extend .buttonBase;
      min-width: 200px;
    }

    &_confirm {
      @extend .buttonBase;

      &_link {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.creditCardUnavailable {
  text-align: center;
}
